import type { FeatureCollection, GeoJsonTypes } from 'geojson';
import type { definitions } from './supabase';
import type { SomeJSONSchema } from 'ajv/dist/types/json-schema';
import type { JSONValue } from '@sveltejs/kit/types/helper';
import type { CombinedVisualisationDef } from './visualisation';
import type { CommonMapSkill, MapSkill, RasterMapSkill, VectorMapSkill } from './map_skills';

// 
// 
// Endpoints
// 
// 
export interface Endpoint<RequestData = any, ResponseBody = any> {
  method: 'POST' | 'GET';
  url: string;
  request_data?: RequestData;
  response_body?: ResponseBody;
}

export interface LoginParams { email: string; password: string; };
export interface TeamParam { team_slug: string; };
export interface ParcelPathParam { parcel_path: string; };
export interface TargetParcelPathParam { target_parcel_path: string; };
export interface KeyParam { key: string; };
export interface JobId { job_id: string };
export type PresignedUrl = string;

export interface CacheProperties {
  team_slug: string;
  parcel_path: string;
  key: string;
}



// 
// 
// App state
// 
// 

export enum RunState {
  not_configured = 'not_configured',
  configured = 'configured',
  running = 'running',
  success = 'success',
  failed = 'failed',
  unknown = 'unknown',
}

export interface DebugFlags {
  debug: boolean;
  power: boolean;
  cache: boolean;
}

// 
// 
// App (rest of things)
// 
// 

export enum KeyPrefix {
  request = 'request',
  result = 'result',
}

export type RemoteResponse<T = any> =
  | ErrorMessage
  | Success<T>

export type ErrorMessage = {
  type: 'error',
  message: string;
}

type Success<T> = {
  type: 'success';
  result: T;
}

export type ParcelArray = string[];

export type CovariateDef = {
  title: string;
  description?: string;
  s3_path: string;
  iso3: string;
}

export interface RemoteFileInfo {
  key: string; // Without the 'parcel_path'
  size?: number;
  filename?: string; // Last part of the key, split by the '/' separator
}

export enum FileFilterType {
  all = 'all',
  request = 'request', // Anything in the 'request' folder
  request_data = 'request_data', // Should be filtered to remove request.json
  result = 'result', // Anything in 'result' folder
  result_data = 'result_data', // Filtered to remove app.log and meta.json
}

export enum ViewType {
  raw = "raw",
  map = "map",
  table = "table",
  json = "json",
  edit = "edit",
  geojson = "geojson",
  form = "form",
}

// Each Form is passed the form_params object from the FormHolder, which looks like...
export interface FormConfig {
  // Static
  algo_use: definitions["algo_uses"];
  docs_root: string;
  validate: (schema: SomeJSONSchema, any) => string[];
  // Derived from Static
  params_schema: SomeJSONSchema;
  // validation_fn: (schema: SomeJSONSchema, params: any) => string[] // Function to generate a set of validation_messages
}

export interface Table { rows: any[]; columns: any[]; }

export type AlgoUseWithAlgo = definitions['algo_uses'] & { algos: definitions['algos'] };

// 
// 
// Mapping and display
// 
// 

export type { CombinedVisualisationDef as GeoVisualisationDef };

// Maplibre-style colour map: [value, colour, ...]
export type ColourValueArray = Array<string | number>;

export type { CommonMapSkill, RasterMapSkill, VectorMapSkill };

// 
// 
// PowerFile
// 
// 

export enum DataContentType {
  tabular = 'tabular',
  object = 'object',
  raster = 'raster',
  raw = 'raw',
}
export interface PowerFile {
  meta: { // All meta properties come from original file object, not the content
    filename: string; // With no folders or extra key info (e.g. 'request.json')
    key: string; // Full key path (e.g. 'request/request.json')
    size: number; // Filesize in bytes
    data_content_type: DataContentType; // Inferred from extension
    filename_without_extension: string; // For creating download filenames
    extension: string; // From filename
    skip_browser_processing: boolean; // Whether to download, or just use a remote reference
    too_large_to_display: boolean; // Compare filesize to config.display.max_file_size_bytes
  };
  original: {
    raw?: string | JSON; // Axios returns ready-parsed JSON, everything else is just a string
  };
  views: {
    geojson?: FeatureCollection; // Required for a map
    table?: Table; // Required for a table
    json?: JSON; // If JSON, but not valid GeoJSON
    string?: string; // Stringified version, could be same as raw
    geo_summary?: GeoSummary;
  };
  map_skills?: MapSkill[];
  graph_skills?: any; // Space for something
  headline_skills?: any; // Space for something
  can?: CanView;
}

export interface CanView {
  map: boolean;
  table: boolean;
  json: boolean;
  raw: boolean
};

export interface GeoSummary {
  sample: {
    geometry_types: GeoJsonTypes[];
    property_field_names: string[];
  };
}




/**
 * API
 */

// Others
export type PublicUser = definitions['users'];


export type EndpointOutput = {
  status?: number;
  headers?: Record<string, string>;
  body?: string | Uint8Array | JSONValue;
};

export interface TypedEndpointOutput<T = any> {
  status?: EndpointOutput['status'];
  headers?: EndpointOutput['headers'];
  body?: T;
};


export interface AuthLocals { // This should be the same as most requests sent from client...
  bucket: string; // From config, could implement an over-ride later
  public_user: PublicUser; // From cookie header / session. If this exists, you have a valid user
  team_slug: string; // From body
  parcel_path?: string; // From body
  key?: string; // From body
}

export interface AppSession {
  jwt: string;
  public_user: PublicUser;
}
