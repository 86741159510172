// Keep following import as a relative reference, so that the API files can use the config too
import type { GeoVisualisationDef } from '$lib/types/types';
import { KeyPrefix } from '$lib/types/types';

interface Config {
  api: {
    api_endpoint: string; // Include the first slash
    headless_route_prefix: string; // Include the first slash, but not the `api_endpoint`
    headless_auth_header: string;
    integrate_route_prefix: string; // Used for Batch updates: Include the first slash, but not the `api_endpoint`
    auth_header: string; // Probably 'Cookie'
    session_cookie_key: string; // probably session
    bucket: string;
    default_job_queue: string;
    result_polling_interval_s: number;
    presigned_link_expiration_seconds: number;
    covariate_s3_bucket: string;
    request_property_name: string;
  };
  data: {
    lat_field: string;
    lng_field: string;
  };
  display: {
    max_filesize_bytes: number;
    max_table_rows: number;
    max_chars: number;
    number_precision: number; // For rendering legends
    legend_palette_steps: number;
  };
  files: {
    s3_path_delimiter: string;
    request_path_prefix: KeyPrefix;
    result_path_prefix: KeyPrefix;
    // Ignore these in non-power file listing
    fn_slug_key: string;
    request_json_key: string;
    job_id_key: string;
    log_file_key: string;
    meta_file_key: string;
  };
  map: {
    base_style_json_url: string;
    default_center: { lng: number, lat: number };
    default_zoom: number;
    default_bounding_box_padding_px: number;
    default_layer_colour: string;
    raster_tiling: {
      cog_titiler_server_url: string; // Instance of titiler
      defaults: {
        scale: number; // Resolution of the tiles i.e. @1x / @2x
        band_index: number;
        resampling_method: string;
        colormap_type: 'linear';  // Only option in the tiling backend
      }
    };
    default_source_id: {
      raster: string;
      vector: string;
    };
    visualisation_version: number;
    default_map_skills: GeoVisualisationDef[];
  };
  ui: {
    colours: string[];
    loading_notification_overlap_ms: number;
    default_email_suffix: string; // Can supply a part-username, without an email suffix e.g. user --> user@lnl.app
  };
  admin: {
    repos: string[];
  };
}

export const config: Config = {
  api: {
    api_endpoint: '/api',
    headless_route_prefix: '/headless',
    headless_auth_header: 'x-api-key',
    integrate_route_prefix: '/integrate',
    auth_header: 'cookie',
    session_cookie_key: 'session',
    bucket: 'lnl-batch-production',
    default_job_queue: 'fargate-queue-1',
    result_polling_interval_s: 30,
    presigned_link_expiration_seconds: 600,
    covariate_s3_bucket: 'https://lnl-covariates.s3.eu-central-1.amazonaws.com',
    request_property_name: 'request',
  },
  data: {
    lat_field: 'lat',
    lng_field: 'lng',
  },
  display: {
    max_filesize_bytes: 10000000,
    max_table_rows: 10,
    max_chars: 10000,
    number_precision: 3,
    legend_palette_steps: 5,
  },
  files: {
    s3_path_delimiter: '/',
    request_path_prefix: KeyPrefix.request,
    result_path_prefix: KeyPrefix.result,
    // Want to ignore these in non-power file listing
    fn_slug_key: 'fn_slug',
    job_id_key: 'job_id',
    request_json_key: KeyPrefix.request + '/request.json',
    log_file_key: KeyPrefix.result + '/app.log',
    meta_file_key: KeyPrefix.result + '/meta.json',
  },
  map: {
    base_style_json_url: "https://api.maptiler.com/maps/ch-swisstopo-lbm-dark/style.json?key=bqOgeEY6udAo3uVwG5zE",
    default_center: { lng: 23.879877186104864, lat: 26.519095934501806 },
    default_zoom: 1.4791908663810414,
    default_bounding_box_padding_px: 20,
    default_layer_colour: 'white',
    raster_tiling: {
      cog_titiler_server_url: 'https://rjeg5mjc81.execute-api.eu-central-1.amazonaws.com',
      defaults: {
        scale: 1,
        band_index: 1, // Assumes only one band
        resampling_method: 'nearest',
        colormap_type: 'linear',
      }
    },
    default_source_id: {
      raster: 'raster-source',
      vector: 'vector-source',
    },
    visualisation_version: 2,
    default_map_skills: [
      {
        version: 2,
        name: 'Greyscale',
        legend_title: 'Values',
        vector_or_raster: 'raster',
        colour_array: ['#fff', '#000'],
        domain_type: 'continuous',
      }, {
        version: 2,
        name: 'Geometry',
        vector_or_raster: 'vector',
        colour_array: ['#fff'],
        domain_type: 'single_value',
      }
    ],
  },
  ui: {
    colours: ["#ec6b01", "#fe8105", "#ffa43e", "#009ca8", "#0088a2", "#214775",],
    loading_notification_overlap_ms: 75,
    default_email_suffix: '@lnl.app',
  },
  admin: {
    repos: ["fn-covariate-extractor", "fn-dbscan-clusterer", "fn-prev-pred", "fn-raster-vector-summary-stats", "fn-village-finder"],
  },
};
